import { createSlice } from '@reduxjs/toolkit'

const pageHistory = createSlice({
  name: 'pageHistory',
  initialState: {
    history: []
  },
  reducers: {
    setHistory: (state, action) => {
      state.history = action.payload
    }
  }
})

export const { setHistory } = pageHistory.actions
export default pageHistory.reducer

import { checkoutReducer } from './checkout'
import jobApplicationReducer from '../slices/jobApplication'
import discountBadge from '../slices/discountBadge'
import clientLocation from '../slices/clientLocation'
import numberOfClients from '../slices/numberOfClients'
import cookieConsent from '../slices/cookieConsent'
import cardComponent from '../slices/cardComponent'
import freeCallLead from '../slices/freeCallLead'
import bookAvailability from '../slices/bookAvailability'
import splitTest from '../slices/splitTest'
import pageHistory from '../slices/PageHistory'
import Quiz from '../slices/Quiz'
import { combineReducers } from 'redux'

export const allReducers = combineReducers({
  checkout: checkoutReducer,
  jobApplication: jobApplicationReducer,
  discountBadge: discountBadge,
  clientLocation: clientLocation,
  cookieConsent: cookieConsent,
  cardComponent: cardComponent,
  numberOfClients: numberOfClients,
  freeCallLead: freeCallLead,
  bookAvailability: bookAvailability,
  splitTest: splitTest,
  pageHistory: pageHistory,
  quiz: Quiz
})

export type RootState = ReturnType<typeof allReducers>

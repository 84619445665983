import styles from '@/styles/components/modal/MobileMenu.module.scss'
import { Button } from '@/components/controls/Button'
import { useMobileMenu } from '@/context/MobileMenuContext'
import { useEffect } from 'react'
import Link from 'next/link'
import { useQuery } from '@/utils/url'
import { useScrollToSection } from '@/hooks/scrollToSection'

export const MobileMenu: React.FC= () => {
  const { isActive, toggle } = useMobileMenu()

  useEffect(()  => {
    if (isActive) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  })

  return (
    <div className={`${styles.mobile_menu} ${isActive ? styles.mobile_menu_active : ''}`}>
      <div className={`${styles.mobile_menu_container} container`}>
        <nav className={styles.mobile_menu_nav}>
          <ul className={`${styles.mobile_menu_ul} nav nav--secondary`}>
            <li className="nav__item nav__item--secondary nav__item--mobile" onClick={toggle}>
              <a href="#" onClick={useScrollToSection('pricing')}><span>Pricing</span></a>
            </li>
            <li className="nav__item nav__item--secondary nav__item--mobile" onClick={toggle}>
              <a href="#" onClick={useScrollToSection('faq')}><span>FAQ</span></a>
            </li>
            <li className="nav__item nav__item--secondary nav__item--mobile" onClick={toggle}>
              <Link href={useQuery('/about')} passHref><span>About</span></Link>
            </li>
            <li className="nav__item nav__item--secondary nav__item--mobile" onClick={toggle}>
              <Link href={useQuery('/post')} passHref><span>Blog</span></Link>
            </li>
            <li className="nav__item nav__item--secondary nav__item--mobile">
              <Link href={useQuery('https://app.commitaction.com/login')} passHref><span className="text-with-gradient">Login</span></Link>
            </li>
            <li className="nav__item nav__item--secondary nav__item--mobile" onClick={toggle}>
              <Link href={useQuery('/contact')} passHref><span className="text-with-gradient">Contact</span></Link>
            </li>
          </ul>
        </nav>
        <Button href={useQuery('/checkout')} onClick={toggle} type="primary" className="absolute bottom-[60px]">Skip Intro & Pay</Button>
      </div>
    </div>
  )
}
